<template>
    <div>
        <v-card class="elevation-1">

            <v-card-title class="justify-center font30">
                <v-row>
                    <v-col cols="2">
                        <v-btn
                        icon
                        color="grey"
                        to="/login"
                        class="mx-1"
                        >
                        <v-icon>mdi-arrow-left</v-icon>
                    </v-btn>
                    </v-col>
                    <v-col cols="8" class="center">
                        {{$t('resetPassword')}}
                    </v-col>
                </v-row>
            </v-card-title>
        
            <v-card-text>
                <v-form 
                v-if="showSendVerificationCodeBtn"
                ref="forgotPasswordForm"
                lazy-validation
                @submit.prevent="customerForgotPassword"
                >
                    <v-text-field
                    v-model="customer.login"
                    id="login"
                    name="login"
                    :label="$t('login')"
                    type="login"
                    :rules="required"
                    ></v-text-field>

                    <div class="mt-5 center">
                        <v-btn 
                        color="blue-grey" 
                        dark
                        type="submit"
                        >
                        {{$t('sendVerificationCode')}}
                        </v-btn>
                    </div>
                </v-form>

                <v-form 
                v-if="showChangePasswordBtn"
                ref="resetPasswordForm"
                lazy-validation
                @submit.prevent="customerResetPassword"
                >
                    <v-text-field
                    v-model="customer.verification_code"
                    id="verification_code"
                    name="verification_code"
                    :label="$t('verificationCode')"
                    type="text"
                    :rules="verificationCodeRules"
                    ></v-text-field>

                    <v-text-field
                    v-model="customer.new_password"
                    id="new_password"
                    name="new_password"
                    :label="$t('newPassword')"
                    type="password"
                    :rules="newPasswordRules"
                    ></v-text-field>

                    <v-text-field
                    v-model="confirm_new_password"
                    id="confirm_new_password"
                    name="confirm_new_password"
                    :label="$t('confirmNewPassword')"
                    type="password"
                    :rules="newPasswordRules.concat(passwordMatchingRule)" 
                    ></v-text-field>
            
                    <div class="mt-5 center">
                        <v-btn 
                        class="mt-5 center"
                        color="blue-grey" 
                        dark
                        type="submit"
                        >
                        {{$t('changePassword')}}
                        </v-btn>
                    </div>
                </v-form>

            </v-card-text>
        </v-card>

    </div>
</template>

<script>
// importing Api requests
import Api from "@/api/Api";

export default {
    // initializing data
    data: function() {
        return {
            customer: {
                login: '',
                verification_code: '',
                new_password: '',
            },
            confirm_new_password: '',
            showSendVerificationCodeBtn: true,
            showChangePasswordBtn: false,
            required: [
                value => !!value || this.$i18n.t('requiredRule'),
            ],
            newPasswordRules: [
                value => !!value || this.$i18n.t('enterPasswordRule'),
                value => (value && /\d/.test(value)) || this.$i18n.t('oneDigitRule'),
                value => (value && /[A-Z]{1}/.test(value)) || this.$i18n.t('capitalLetterRule'),
                value => (value && /[^A-Za-z0-9]/.test(value)) || this.$i18n.t('specialCharRule'),
                value => (value && value.length > 6 ) || this.$i18n.t('minCharRule'),
            ],
            verificationCodeRules: [
                value => !!value || this.$i18n.t('verificationCodeRule'),
                value => (value && value.length === 6 ) || this.$i18n.t('verificationCodeLengthRule'),
            ]
        }
    },
    computed: {
        // validates if new password is the same as the confirm new password
        passwordMatchingRule() {
            return () => this.customer.new_password === this.confirm_new_password || this.$i18n.t('mustMatchRule');
        }
    },
    methods: {
        // sends the customer login to backend
        async customerForgotPassword() {
            try {
                if(this.$refs.forgotPasswordForm.validate()) {
                    this.$emit('loading', true);
                    // sending the customer login
                    await Api.customerForgotPassword(this.customer.login);

                    // showing success component
                    this.$toast.success(this.$t('forgotPasswordSuccessMsg'), {
                        timeout: 4000
                    });
                    this.$emit('loading', false);
                    this.showSendVerificationCodeBtn = false;
                    this.showChangePasswordBtn = true;
                }
            } catch (error) {
                this.$emit('loading', false);
                if (error.response) {
                    this.$emit('error-message', error.response.data.message);
                } else if (error.request) {
                    this.$emit('error-message', error.request);
                } else {
                    this.$emit('error-message', error.message);
                }
            }
        },
        // sends the login, new password and verification code to the backend
        async customerResetPassword() {
            try {
                if(this.$refs.resetPasswordForm.validate()) {
                    // appending the required data to backend
                    let form_data = new FormData();
                    form_data.append('login', this.customer.login);
                    form_data.append('new_password', this.customer.new_password);
                    form_data.append('verification_code', this.customer.verification_code);

                    // making request to backend
                    await Api.customerResetPassword(form_data);

                    // showing success component
                    this.$toast.success(this.$t('resetPasswordSuccessMsg'), {
                        timeout: 4000
                    });

                    this.$router.push('/login')
                }
            } catch (error) {
                if (error.response) {
                    this.$emit('error-message', error.response.data.message);
                } else if (error.request) {
                    this.$emit('error-message', error.request);
                } else {
                    this.$emit('error-message', error.message);
                }
            }
        },
    }
};
</script>