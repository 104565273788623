const en = {
    // Single letters
    yes: 'Yes',
    no: 'No',
    credit: 'Credit',
    debit: 'Debit',

    // Validations / Rules
    ccNumberRule: 'Card number must have ',
    ccNumberRule2: ' digits',
    ccvRule: 'CVV must have 3 or 4 digits',
    zipRule: 'Zip must have 5 digits',
    expDateRule: 'Card is expired',
    requiredRule: 'Required',
    expDateFormatRule: 'Format should be: YYYY-MM',

    enterPasswordRule: 'Please enter a password',
    oneDigitRule: 'At least one digit',
    capitalLetterRule: 'At least one capital latter',
    specialCharRule: 'At least one special character (!@#$)',
    minCharRule: 'minimum 6 characters',
    mustMatchRule: 'Passwords must match',
    samePasswordsRule: 'New password cannot be the same as the current password',

    balanceRule: 'Amount to be paid must be greater than ${balance}',

    verificationCodeRule: 'Please enter a verification code',
    verificationCodeLengthRule: 'verification code must have 6 characters',

    // Buttons
    cancelBtn: 'Cancel',
    continueBtn: 'Continue',
    backBtn: 'Back',
    submitBtn: 'Submit',
    acceptBtn: 'Accept',
    rejectBtn: 'Reject',
    editBtn: 'Edit',
    showBtn: 'Show',
    downloadBtn: 'Download',
    saveBtn: 'Save',
    closeBtn: 'Close',
    payNowBtn: 'Pay Now',
    createTicketBtn: 'Create Ticket',
    createBtn: 'Create',
    updateBtn: 'Update',
    loadingBtn: 'Loading...',
    loginBtn: 'Login',
    payNowWithPaypalBtn: 'Pay now with PayPal',

    // Login view 
    login: 'Login',
    password: 'Password',
    forgotPassword: 'Forgot Password?',

    // Reset Password view
    resetPassword: 'Reset Password',
    sendVerificationCode: 'Send Verification Code',
    verificationCode: 'Verification Code',

    // mail service
    goToMail: 'Go to mail',
    serviceResetPasswordHeader:'Change password for',
    resetPasswordAceptance: 'Password changed',
    resetPasswordValidation: 'Password must include at least 8 characters, 1 lowercase letter, 1 uppercase letter, 1 special character, and 1 number',
    resetPasswordMatching:'Password does not match',

    // Sidebar
    dashboard: 'Dashboard',
    profile: 'My Profile',
    logout: 'Logout',
    billingInfo: 'Billing Information',
    tickets: 'Trouble Tickets',

    // Services View
    phone: 'Phone',
    internet: 'Internet',
    other: 'Other',
    yourSubscription: 'Your Subscription(s)',
    product: 'Product',
    totalDownloadedData: 'Total downloaded data this month',
    totalUploadedData: 'Total uploaded data this month',
    totalDataUsed: 'Total data used this month',
    down: 'down',
    up: 'up',
    duration: 'Duration',
    ip: 'Current IP Address',
    search: 'Search',
    connectTime: 'Time of call',
    services: 'Services',

    // Dashboard view
    contactInfo: 'Contact Information',
    balance: 'Balance',
    invoiceBalance: 'Invoice Balance',
    adjustments: 'Credits/Adjustments',
    monthlyTransaction: 'Monthly Transactions',
    currentBalance: 'Current Balance',
    viewProfile: 'View profile',
    yourBill: 'Your Bill',
    yourServices: 'Your Services',
    amountDueOn: 'Amount Due on',
    billDetails: 'Bill Details',
    seeMonthlyInvoice: 'Want to see the monthly invoice?',
    seeBill: 'See Bill',
    inactive: 'Inactive',
    active: 'Active',
    serviceStatus: 'Service(s) Status',
    noService: 'You do not have any service at the moment',

    xgsConfirmationNoticeHeader:'XGS Upgrade Confirmation',
    xgsRequireNotice1:'A request to upgrade to XGS was made. Please confirm your upgrade request details below:',
    xgsTicketAcceptance:'XGS upgrade was accepted, ticket number is ',
    xgsTicketCanceled:'XGS Request was rejected',
    xgsProductLabel:'New service plan: ',
    xgsDescription:'A new 24-month term will commence upon activation of XGS service.  For more information, refer to the Optico Fiber Subscriber Agreement:',

    ssnRequireNoticeHeader:'Social Security Requirement',
    ssnRequireNotice1:'The Department of the Treasury requires us, as an Internet access service provider, to fill out a ',
    ssnRequireNotice2:' form for each client. In order to meet this requirement we need you to provide us with the social security number of ',
    // Billing information tabs
    invoices: 'Invoices',
    makePayment: 'Make a Payment',
    paymentInformation: 'Payment Information',

    // Billing information's Billing summary tab
    from: 'From',
    until: 'Until',
    voiceCalls: 'Voice Calls',
    payments: 'Payments',
    taxes: 'Taxes',
    total: 'Total',

    // Billing information's Invoice tab
    viewDatatable: 'View',
    noDatatable: 'No.',
    periodStartsDatatable: 'Period Starts',
    periodEndsDatatable: 'Period Ends',
    dueDateDatatable: 'Due Date',
    periodTotalDatatable: 'Period Total',
    paymentAdjDatatable: 'Payment / Adjustments',
    paymentStatusDatatable: 'Payment Status',
    invoiceDetails: 'Invoice Details',
    downloadInvoice: 'Download Invoice',
    invoiceDate: 'Invoice Date',
    serviceFrom: 'Service from',
    to: 'To',
    subscriptionCharges: 'Subscription Charges',
    noCharges: 'There are no charges for this invoice',
    otherCharges: 'Other Charges',
    calls: 'Calls',
    totalDue: 'Total Due by',
    manualCharges: 'Manual charges',
    totalOtherCharges: 'Total other charges',
    totalSubscriptions: 'Total subscriptions',

    // Billing information's Make a payment tab
    chooseAmount: 'Choose amount',

    // Trouble tickets view 
    subjectDatatable: 'Subject',
    statusDatatable: 'Status',
    createdDatatable: 'Created',
    lastUpdatedDatatable: 'Last Updated',
    requestorsDatatable: 'Requestors',

    // Billing information's Make a Payment tab
    amountToCharge: 'Amount to be Paid',
    customAmount: 'Custom Amount',
    amount: 'Amount',
    pay: 'Pay',
    paymentMethod: 'Payment Method',
    summary: 'Summary',
    changePaymentMethod: 'Change payment method',
    messageLine1: 'Your balance is ',
    messageLine2: '. You have nothing to pay at the moment.',
    newPayment: 'New Payment',
    payWithCard: 'Pay with another credit card',

    // Billing information's Payment Information tab
    savedPaymentMethod: 'Saved Payment Method',
    newPaymentMethod: 'New Payment Method',
    checking: 'Checking Account',
    card: 'Credit Card',
    paypal: 'PayPal',
    payWithChecking: 'Pay with your checking account',
    changeCard: 'Register a new credit card',
    savedCard: 'Registered payment method details',
    routingNumber: 'Routing Number',
    accountNumber: 'Account Number',
    accountName: 'Account Name',
    cardNumber: 'Card Number',
    cvv: 'CVV',
    expDate: 'Expiration Date',
    cardholderName: 'Cardholder Name',
    country: 'Country',
    billingAddress: 'Billing Address',
    city: 'City',
    state: 'State',
    zip: 'Postal code',
    billingPhoneNumber: 'Billing Phone Number',
    setCardAsDefault: 'Want to set this card as default?',

    // My profile view
    personalInfo: 'Personal Information',
    addressInfo: 'Address Information',
    changePassword: 'Change Password',
    companyName: 'Company Name',
    firstname: 'First name',
    midi: 'Initial',
    lastname: 'Last name',
    address1: 'Address line 1',
    address2: 'Address line 2',
    email: 'Email',
    contact: 'Contact',
    phoneNumber: 'Phone number',
    fax: 'Fax',
    altContact: 'Alt. contact',
    altPhone: 'Alt. phone number',
    currPassword: 'Current password',
    newPassword: 'New password',
    confirmNewPassword: 'Confirm new password',
    name: 'Account name',

    // Footer
    address: 'Address',
    contacts: 'Contacts',
    sales: 'Sales',
    billing: 'Billing',
    techSupport: 'Tech. Support',
    custService: 'Customer Service',
    desk: 'Optico Fiber Desk',
    termsOfService: 'Terms of Service',
    usePolicy: 'Acceptable Use Policy',
    suscriberAgreement: 'Subscriber Agreement',
    networkManagement: 'Network Management',

    // Sweet alert
    success: 'Success!',
    paymentSuccessMsg: 'Your payment has been made',
    savePaymentMethodSuccessMsg: 'Your new payment method has been saved',
    createTicketSuccessMsg: 'Your new ticket has been created',
    updatePasswordSuccessMsg: 'Your new password has been updated',
    forgotPasswordSuccessMsg: 'Your verification code was sent to the email: ',
    resetPasswordSuccessMsg: 'Password was successfully reset',
    wrongCredentialsMsg: 'Incorrect login or password',
    unAuthorizedMsg: 'You do not have access to this platform',

    // Success Messages
    paymentCompleted: 'Your payment was completed!',
    paymentCompletedSubheading: 'If you have any questions referring to this payment, please contact customer service.',
    completedPaymentInformation: 'Completed payment information',
    paidAmount: 'Paid Amount',
    transactionId: "Transaction Number",
    accountBalance: 'Account Balance',

    // Error Messages
    paymentError: 'There has been an error making your payment!',
    paymentErrorSubheading: 'For more information about the error, contact customer service.',
    errorMsg: 'Error message',

    //     Suspended Message
    suspendedAlert: 'There seems to be a problem with your account. Please login for more information.\n' +
        'You can also contact our customer service department at 787-957-6000.',

    captiveSubscriberHeader:'Welcome to Optico Fiber!',
    captiveSubscriberSubHeader1:'To signup for service, please click',
    captiveSubscriberSubHeader2:' or call 787-957-6000',

};

export default en;