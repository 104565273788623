<template>
    <div>
        <v-card elevation="1" class="my-5"> 
            <v-row>
                <v-col cols="12" lg="12">
                    <v-card-text>
                        <v-row class="font16">
                            <v-col cols="5" md="4">
                                {{$t('product')}}: 
                            </v-col>
                            <v-col cols="5" md="4">
                                <strong>{{otherService.product_visible_name}}</strong>
                            </v-col>
                        </v-row>

                        <v-row class="font16">
                            <v-col cols="5" md="4">
                                {{$t('phoneNumber')}}: 
                            </v-col>
                            <v-col cols="5" md="4">
                                <strong>{{otherService.id}}</strong>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-col>
            </v-row>
        </v-card>
    </div>
</template>

<script>

export default ({
    // receiving customer other services from parent component
    props: ['otherService'],
})
</script>
