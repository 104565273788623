<template>
    <v-card elevation="1" class="my-5"> 
        <v-row>
            <v-col cols="12" lg="12">
                <v-card-text>
                    <v-row class="font16">
                        <v-col cols="5" md="4">
                            {{$t('product')}}: 
                        </v-col>
                        <v-col cols="5" md="4">
                            <strong>{{internetService.product_visible_name}}</strong>
                        </v-col>
                        <v-col cols="2" md="4" class="font16 text-right">
                            <div class="mr-5" v-if="internetService.pppoe">
                                <v-avatar
                                size="25px"
                                >
                                    <v-img
                                    :src="require('@/assets/icons/Icon_world_connected_128.png')" 
                                    ></v-img>
                                </v-avatar>
                            </div>
                            <div class="mr-5" v-else>
                                <v-avatar
                                size="25px"
                                >
                                    <v-img
                                    :src="require('@/assets/icons/Icon_world_disconnected_128.png')" 
                                    ></v-img>
                                </v-avatar>
                            </div>
                        </v-col>
                    </v-row>

                    <v-row class="font16" v-if="internetService.pppoe">
                        <v-col cols="5" md="4">
                            {{$t('ip')}}:
                        </v-col>
                        <v-col cols="5" md="8">
                            <strong>{{internetService.pppoe.ip}}</strong>
                        </v-col>
                    </v-row>

                    <v-row class="font16">
                        <v-col cols="5" md="4">
                            {{$t('totalDownloadedData')}}: 
                        </v-col>
                        <v-col cols="5" md="8">
                            <strong>{{bytes(internetService.broadbandKb.bytes_downloaded, 'GB')}}</strong>
                        </v-col>
                    </v-row>

                    <v-row class="font16">
                        <v-col cols="5" md="4">
                            {{$t('totalUploadedData')}}: 
                        </v-col>
                        <v-col cols="5" md="8">
                            <strong>{{bytes(internetService.broadbandKb.bytes_uploaded, 'GB')}}</strong>
                        </v-col>
                    </v-row>

                    <v-row class="font16">
                        <v-col cols="5" md="4">
                            {{$t('totalDataUsed')}}: 
                        </v-col>
                        <v-col cols="5" md="8">
                            <strong>{{bytes(internetService.broadbandKb.bytes_uploaded 
                                + internetService.broadbandKb.bytes_downloaded, 'GB')}}</strong>
                        </v-col>
                    </v-row>

                </v-card-text>
            </v-col>
        </v-row>
    </v-card>
       
</template>

<script>

export default ({
    // receiving customer internet services from parent component
    props: ['internetService'],
    methods: {
        // method that receives the data and the convert to
        // returns the data converted to the specified convert to
        bytes(data, convert_to) {
            const const_term = 1024;
            if (convert_to === "KB") {
                return (data / const_term).toFixed(2) + "KB";
            } else if (convert_to === "MB") {
                return (data / const_term ** 2).toFixed(2) + "MB";
            } else if (convert_to === "GB") {
                return (data / const_term ** 3).toFixed(2) + "GB";
            } else if (convert_to === "TB") {
                return (data / const_term ** 4).toFixed(2) + "TB";
            } else {
                return "Please pass valid option";
            }
        },
    }
})
</script>
