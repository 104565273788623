<template>
    <v-card elevation="1">
        <v-card-title>{{$t('yourServices')}}</v-card-title>

        <v-card-text>
            <div v-if="hasServices">
              <v-row>
                  <v-col v-if="services.internetService" :cols="12/columns"  class="center">
                      <v-avatar
                      size="42px"
                      >
                          <v-img
                          :src="require('@/assets/icons/Icon_world_128.png')"
                          ></v-img>
                      </v-avatar>
                      <div class="mt-4">
                          <router-link to="/services?tab=internet">{{$t('internet')}}</router-link>
                      </div>
                  </v-col>

                  <v-col v-if="services.phoneService" :cols="12/columns"  class="center">
                      <v-icon large color="grey darken-2">mdi-phone-in-talk</v-icon>
                      <div class="mt-4">
                          <router-link to="/services?tab=phone">{{$t('phone')}}</router-link>
                      </div>
                  </v-col>
  <!--                <v-col :cols="12/columns" class="center">-->
  <!--                    <v-icon large color="black">mdi-television</v-icon>-->
  <!--                    <div class="mt-4">-->
  <!--                        <a target="_blank" href="https://www.fubo.tv/lp/StreamTV/?irad=480949&irmp=1804231">Fubo TV</a>-->
  <!--                    </div>-->
  <!--                </v-col>-->
              </v-row>
            </div>

          <div v-else class="my-8 center">
            {{$t('noService')}}
          </div>
        </v-card-text>

    </v-card>
</template>

<script>

export default {
    // receiving the services data from parent component
    props: ['services'],
    // initializing data
    data: () => ({
        columns: 0,
        hasServices: false,
    }),
    watch: {
        // watching which service the customer has, to determine how many
        // columns show
        services() {
          if (this.services.internetService) this.columns += 1;
          if (this.services.phoneService) this.columns += 1;
          if(this.services.internetService || this.services.phoneService || this.services.emailService || this.services.otherService)
            this.hasServices = true;
        }
    }
}
</script>
