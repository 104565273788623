var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticStyle:{"height":"100%"},attrs:{"elevation":"1"}},[_c('v-card-title',[_vm._v(_vm._s(_vm.$t('serviceStatus')))]),_c('v-card-text',[(_vm.hasServices)?_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[(_vm.services.internetService)?_c('div',_vm._l((_vm.services.internetService),function(service){return _c('div',{key:service.id},[(service.pppoe ? true :  false)?_c('div',{staticClass:"my-5"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-avatar',{attrs:{"size":"32px"}},[_c('v-img',_vm._g(_vm._b({attrs:{"src":require('@/assets/icons/Icon_world_128.png')}},'v-img',attrs,false),on))],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(service.product_visible_name))])]),_c('v-icon',{staticClass:"ml-5",attrs:{"md":"","color":"green"}},[_vm._v("mdi-checkbox-marked-circle")]),_vm._v(" "+_vm._s(_vm.$t('active'))+" ")],1):_c('div',{staticClass:"my-5"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-avatar',{attrs:{"size":"32px"}},[_c('v-img',_vm._g(_vm._b({attrs:{"src":require('@/assets/icons/Icon_world_128.png')}},'v-img',attrs,false),on))],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(service.product_visible_name))])]),_c('v-icon',{staticClass:"ml-5",attrs:{"md":"","color":"red"}},[_vm._v("mdi-close")]),_vm._v(" "+_vm._s(_vm.$t('inactive'))+" ")],1)])}),0):_vm._e()]),_c('v-col',{attrs:{"cols":"6"}},[(_vm.services.phoneService)?_c('div',_vm._l((_vm.services.phoneService),function(service){return _c('div',{key:service.id},[(service.sip_status)?_c('div',{staticClass:"my-5"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"md":"","color":"grey darken-2"}},'v-icon',attrs,false),on),[_vm._v("mdi-phone-in-talk")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(service.product_visible_name))])]),_c('v-icon',{staticClass:"ml-5",attrs:{"md":"","color":"green"}},[_vm._v("mdi-checkbox-marked-circle")]),_vm._v(" "+_vm._s(_vm.$t('active'))+" ")],1):_c('div',{staticClass:"my-5"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"md":"","color":"grey darken-2"}},'v-icon',attrs,false),on),[_vm._v("mdi-phone-in-talk")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(service.product_visible_name))])]),_c('v-icon',{staticClass:"ml-5",attrs:{"md":"","color":"red"}},[_vm._v("mdi-close")]),_vm._v(" "+_vm._s(_vm.$t('inactive'))+" ")],1)])}),0):_vm._e()])],1)],1):_c('div',{staticClass:"my-8 center"},[_vm._v(" "+_vm._s(_vm.$t('noService'))+" ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }