import Vue from 'vue';
import VueI18n from 'vue-i18n';
import es from "./locales/es";
import en from "./locales/en";

Vue.use(VueI18n);

// internationalization variables
const variables = {
    'en': en,
    'es': es,
};

const i18n = new VueI18n({
    locale: 'en',               // set locale
    fallbackLocale: 'es',       // set fallback locale
    messages: variables,        // set locale vars
    datetimeFormats: {          // setting format for date
        'en': {
            long: {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
            }
        },
        'es': {
            long: {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
            }
        }
    }
});

export default i18n;
 