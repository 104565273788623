<template>
  <v-container class="width50">
        <v-row>
            <v-col cols="6" class="font25">
                {{$t('yourSubscription')}}
            </v-col>
        </v-row>
        <div v-for="service in emailService" v-bind:key="service.id">
            <v-row>
                <v-col
                cols="12"
                sm="12"
                >
                    <subscription-info
                    :emailService="service"
                    @loading="loading"
                    @error-message="showError"
                    />
                </v-col>
            </v-row>
        </div>
  </v-container>
</template>

<script>
// importing child components
import SubscriptionInfo from '@/components/Services/Email/SubscriptionInfoCard.vue';

export default {
    name: 'email-',
    // child components that are being displayed
    components: {
        SubscriptionInfo,
    },
    // data received from parent component
    props: ['emailService'],
    methods: {
        // showing error message with message received
        showError(msg){
            this.$emit('error-message', msg);
        },
        // setting loading component to true or false, depending
        // in what's received
        loading(val) {
            this.$emit('loading', val);
        }
    },
}
</script>
