<template>
    <v-card elevation="1" class="my-5"> 
        <v-row>
            <v-col cols="12" lg="12">
                <v-card-text>
                    <v-row class="font16">
                        <v-col cols="5" md="4">
                            {{$t('product')}}: 
                        </v-col>
                        <v-col cols="5" md="4">
                            <strong>{{phoneService.product_visible_name}}</strong>
                        </v-col>
                        <v-col cols="2" md="4" class="font16 text-right">
                            <div class="mr-5" v-if="phoneService.sip_status.sip_status">
                                <v-icon 
                                md
                                color="green"
                                >mdi-phone-check</v-icon>
                            </div>
                            <div class="mr-5" v-else>
                                <v-icon 
                                md
                                color="red"
                                >mdi-phone-remove</v-icon>
                            </div>
                        </v-col>
                    </v-row>

                    <v-row class="font16">
                        <v-col cols="5" md="4">
                            {{$t('phoneNumber')}}: 
                        </v-col>
                        <v-col cols="5" md="4">
                            <strong>{{phoneService.id}}</strong>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>

export default ({
    // receiving customer phone services from parent component
    props: ['phoneService'],
})
</script>
