<template>
  <v-container class="width50">
    <v-row>
      <v-col cols="6" class="font25">
        {{$t('yourSubscription')}}
      </v-col>
    </v-row>

    <div v-for="service in phoneService" v-bind:key="service.id">
      <v-row>
        <v-col
        cols="12"
        sm="12"
        >
          <subscription-info
          v-bind:phoneService="service"
          @loading="loading"
          @error-message="showError"
          />
        </v-col>
      </v-row>
    </div>

    <v-row>
      <v-col
      cols="12"
      sm="12"
      >
        <voice-calls-table
        v-bind:phoneService="phoneService"
        @loading="loading"
        @error-message="showError"
        />
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
// importing child components
import SubscriptionInfo from '@/components/Services/Phone/SubscriptionInfoCard.vue';
import VoiceCallsTable from '../../components/Services/Phone/VoiceCallsTable.vue';

export default {
  name: 'phone-',
  // child components that are being displayed
  components: {
    SubscriptionInfo,
    VoiceCallsTable,
  },
  // data received from parent component
  props: ['phoneService'],
  methods: {
    // showing error message with message received
    showError(msg){
      this.$emit('error-message', msg);
    },
    // setting loading component to true or false, depending
    // in what's received
    loading(val) {
      this.$emit('loading', val);
    }
  },
}
</script>
