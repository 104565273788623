<template>
    <v-skeleton-loader 
    :loading="loading"
    type="table">
        <v-card>
            <v-data-table
            :headers="ticketHeaders"
            :items="ticketsData"
            >
            <template v-slot:[`item.id`]="{item}">
                <a target="_blank" :href="`https://rt.criticalhub.com:8800/SelfService/Display.html?id=${item.id}`">
                    {{ item.id }}
                </a>
            </template>
            </v-data-table>
        </v-card>
    </v-skeleton-loader>
</template>

<script>
// importing Api requests
import Api from '@/api/Api';

export default {
    name: 'tickets-datatable',
    // initializing data
    data: () => ({
      ticketsData: [],
      loading: false,
  }), 
    computed: {
        // setting the tickets datatable headers
        ticketHeaders() {
            return [
                { text: 'No', value: 'id' },
                { text: this.$t('subjectDatatable'), value: 'subject' },
                { text: this.$t('statusDatatable'), value: 'status' },
                { text: this.$t('createdDatatable'), value: 'created' },
                { text: this.$t('lastUpdatedDatatable'), value: 'last_updated' },
                { text: this.$t('requestorsDatatable'), value: 'requestors' },
            ]
        }
    },
    // fetches the customer's ticket once the component is mounted
    mounted() {
        this.loading = true;
        this.getRTTicketsData();
    },
    methods: {
        // fetching the customer's tickets
        async getRTTicketsData() {
            try {
                // awaiting for response with the ticket list
                let response = await Api.getCustomerRtTicketList(
                    this.$store.getters.i_customer, 
                    this.$store.getters.token);
                // assigning response data to the tickets data variable
                this.ticketsData = response;
            } catch(error) {
                if (error.response.status === 401) {
                  await this.$store.dispatch('logout');
                } else if (error.response) {
                    this.$emit('error-message', error.response.data.message)
                } else if (error.request) {
                    this.$emit('error-message', error.request)
                } else {
                    this.$emit('error-message', error.message)
                }
            }
            this.loading = false;
        }
    },
}
</script>