<template>
  <v-app id="app" class="customizedFont" style="background: #EBECF0;">
    <template>
      <sidebar/>
    </template>
    <v-main> 
      <v-container>
        <router-view />
      </v-container>
    </v-main>
    <template>
      <black-footer />
    </template>
  </v-app>
</template>

<script>
import Sidebar from './components/Navbar.vue';
import BlackFooter from "./components/BlackFooter.vue";

export default {
  name: 'App',
  // components that are being displayed
  components: {
    Sidebar,
    BlackFooter
  },
}
</script>
