const es = {
    // Single letters
    yes: 'Sí',
    no: 'No',
    credit: 'Crédito',
    debit: 'Débito',

    // Validations / Rules
    ccNumberRule: 'La tarjeta de crédito debe tener ',
    ccNumberRule2: ' dígitos',
    ccvRule: 'CVV debe tener 3 o 4 dígitos',
    zipRule: 'Zip debe tener 5 dígitos',
    expDateRule: 'La tarjeta de crédito esta expirada',
    requiredRule: 'Requerido',
    expDateFormatRule: 'Formato debe ser: YYYY-MM',

    enterPasswordRule: 'Entre una contraseña',
    oneDigitRule: 'Al menos un dígito',
    capitalLetterRule: 'Al menos una letra mayúscula',
    specialCharRule: 'Al menos un caractér especial (!@#$)',
    minCharRule: 'mínimo 6 caracteres',
    mustMatchRule: 'Contraseñas deben ser iguales',
    samePasswordsRule: 'La nueva contraseña no debe ser igual a la contraseña actual',

    balanceRule: 'El monto a pagar debe ser mayor a ${balance}',

    verificationCodeRule: 'Entre un código de verificación válido',
    verificationCodeLengthRule: 'El código de verificación debe tener 6 dígitos',

    // Buttons
    cancelBtn: 'Cancelar',
    continueBtn: 'Continuar',
    backBtn: 'Atrás',
    acceptBtn: 'Aceptar',
    rejectBtn: 'Rechazar',
    submitBtn: 'Someter',
    editBtn: 'Editar',
    showBtn: 'Mostrar',
    downloadBtn: 'Descargar',
    saveBtn: 'Guardar',
    closeBtn: 'Cerrar',
    payNowBtn: 'Pagar Ahora',
    createTicketBtn: 'Crear Ticket',
    createBtn: 'Crear',
    updateBtn: 'Actualizar',
    loadingBtn: 'Cargando...',
    loginBtn: 'Iniciar Sesión',
    payNowWithPaypalBtn: 'Paga ahora con PayPal',

    // Login view 
    login: 'Nombre de Usuario',
    password: 'Contraseña',
    forgotPassword: 'Olvidó su contraseña?',

    // Reset Password view
    resetPassword: 'Reestablecer Contraseña',
    sendVerificationCode: 'Enviar código de verificación',
    verificationCode: 'Código de verificación',

    // email service
    goToMail: 'Ir al email',
    serviceResetPasswordHeader:'Cambiar contraseña para',
    resetPasswordAceptance: 'Contraseña cambiada',
    resetPasswordValidation: 'Contraseña debe incluir minimo 8 caracteres,1 letra minúscula,1 letra mayúscula,1 caracter especial y un numero.',
    resetPasswordMatching:'Contraseña no coincide',

    // Sidebar
    dashboard: 'Tablero',
    profile: 'Mi Perfil',
    logout: 'Cerrar Sesión',
    billingInfo: 'Información de Facturación',
    tickets: 'Boletos de Incidencia',

    // Services View
    phone: 'Teléfono',
    internet: 'Internet',
    other: 'Otros',
    email: 'Correo Electrónico',
    yourSubscription: 'Tú Suscripción',
    product: 'Producto',
    totalDownloadedData: 'Data total descargada este mes',
    totalUploadedData: 'Data total subida este mes',
    totalDataUsed: 'Data total usada este mes',
    down: 'abajo',
    up: 'arriba',
    duration: 'Duración',
    ip: 'Dirección de IP Actual',
    search: 'Buscar',
    connectTime: 'Hora de llamada',
    services: 'Servicios',

    // Dashboard view
    contactInfo: 'Información de Contacto',
    balance: 'Balance',
    invoiceBalance: 'Balance de factura',
    adjustments: 'Créditos/Ajustes',
    viewProfile: 'Ver perfil',
    yourBill: 'Tú Factura',
    yourServices: 'Tus Servicios',
    amountDueOn: 'Cantidad Adeudada en',
    currentBalance: 'Balance corriente',
    monthlyTransaction: 'Transacciones Mensuales',
    billDetails: 'Detalles de Factura',
    seeMonthlyInvoice: 'Desea ver su factura mensual?',
    seeBill: 'Ver Factura',
    inactive: 'Inactivo',
    active: 'Activo',
    serviceStatus: 'Estatus de Servicio(s)',
    noService: 'Usted no tiene ningún servicio por el momento',

    xgsConfirmationNoticeHeader:'Confirmación Actuazar a XGS ',
    xgsRequireNotice1:'Se realizó una solicitud para actualizar a XGS. Confirme los detalles de su solicitud de actualización a continuación:',
    xgsTicketAcceptance:'Pedido de XGS fue aceptado, el número de ticket es ',
    xgsTicketCanceled:'Pedido de XGS fue cancelado',
    xgsProductLabel:'Nuevo plan de servicio: ',
    xgsDescription:'Un nuevo plazo de 24 meses comenzará con la activación del servicio XGS. Para obtener más información, consulte el Acuerdo de Suscriptor de Fibra Óptica: ',

    ssnRequireNoticeHeader:'Requerimiento de Seguro Social',
    ssnRequireNotice1: 'El Departamento de Hacienda nos require, como proveedor de servicio de acceso a Internet, que llenemos un formulario ',
    ssnRequireNotice2: ' para cada cliente. Para poder cumplir con este requisito necesitamos que nos provea el número de seguro social de ',

    // Billing information tabs
    invoices: 'Facturas',
    makePayment: 'Realizar Pago',
    paymentInformation: 'Método de Pago',

    // Billing information's Billing summary tab
    from: 'Desde',
    until: 'Hasta',
    voiceCalls: 'Llamadas de Voz',
    payments: 'Pagos',
    taxes: 'Impuestos',
    total: 'Total',

    // Billing information's Invoice tab
    viewDatatable: 'Ver',
    noDatatable: 'No.',
    periodStartsDatatable: 'Comienzo de Período',
    periodEndsDatatable: 'Fin de Período',
    dueDateDatatable: 'Fecha de Vencimiento',
    periodTotalDatatable: 'Período Total',
    paymentAdjDatatable: 'Pagos / Ajustes',
    paymentStatusDatatable: 'Estado del Pago',
    invoiceDetails: 'Detalles de Factura',
    downloadInvoice: 'Descargar Factura',
    invoiceDate: 'Fecha de Factura',
    serviceFrom: 'Servicio desde',
    to: 'Para',
    subscriptionCharges: 'Cargos de Suscripción',
    noCharges: 'No hay cargos para esta factura',
    otherCharges: 'Otros Cargos',
    calls: 'Llamadas Telefóncas',
    totalDue: 'Total adeudado para',
    manualCharges: 'Cargos manuales',
    totalOtherCharges: 'Total de otros cargos',
    totalSubscriptions: 'Total de suscripciones',
    
    // Billing information's Make a payment tab
    chooseAmount: 'Escoga cantidad',

    // Trouble tickets view 
    subjectDatatable: 'Asunto',
    statusDatatable: 'Estatus',
    createdDatatable: 'Creado',
    lastUpdatedDatatable: 'Última Actualización',
    requestorsDatatable: 'Solicitante',

    // Billing information's Make a Payment tab
    amountToCharge: 'Cantidad a Pagar',
    customAmount: 'Cantidad Personalizada',
    amount: 'Cantidad',
    pay: 'Pagar',
    paymentMethod: 'Método de Pago',
    summary: 'Resumen',
    changePaymentMethod: 'Cambiar método de pago',
    messageLine1: 'Su balance es de ',
    messageLine2: '. No tiene balance pendiente para pagar por el momento.',
    newPayment: 'Nuevo Método de Pago',
    payWithCard: 'Realice su pago con una nueva tarjeta de crédito',

    // Billing information's Payment Information tab
    savedPaymentMethod: 'Método de Pago Guardado',
    newPaymentMethod: 'Nuevo Método de Pago',
    checking: 'Cuenta de Banco',
    card: 'Tarjeta de Crédito',
    paypal: 'PayPal',
    payWithChecking: 'Realiza su pago con una cuenta de banco',
    changeCard: 'Registre una nueva tarjeta de crédito',
    savedCard: 'Detalles de tarjeta registrada',
    routingNumber: 'Número de Ruta',
    accountNumber: 'Número de Cuenta',
    accountName: 'Nombre en la Cuenta',
    cardNumber: 'Número de Tarjeta',
    cvv: 'CVV',
    expDate: 'Fecha de Expiración',
    cardholderName: 'Nombre en la Tarjeta',
    country: 'País',
    billingAddress: 'Dirección',
    city: 'Ciudad',
    state: 'Estado',
    zip: 'Código Postal',
    billingPhoneNumber: 'Número de Teléfono',
    setCardAsDefault: 'Desea poner esta tarjeta como predeterminada?',

    // My profile view
    personalInfo: 'Información Personal',
    addressInfo: 'Información de Dirección',
    changePassword: 'Cambiar Contraseña',
    companyName: 'Nombre de la compañía',
    firstname: 'Primer nombre',
    midi: 'Inicial',
    lastname: 'Apellidos',
    address1: 'Línea 1',
    address2: 'Línea 2',
    contact: 'Contacto',
    phoneNumber: 'Número de teléfono',
    fax: 'Fax',
    altContact: 'Contacto alterno',
    altPhone: 'Número de teléfono alterno',
    currPassword: 'Contraseña actual',
    newPassword: 'Nueva contraseña',
    confirmNewPassword: 'Confirmar nueva contraseña',
    name: 'Nombre de la cuenta',

    // Footer
    address: 'Dirección',
    contacts: 'Contactos',
    sales: 'Ventas',
    billing: 'Facturación',
    techSupport: 'Soporte Técnico',
    custService: 'Servicio al Cliente',
    desk: 'Optico Fiber Desk',
    termsOfService: 'Términos y Condiciones',
    usePolicy: 'Politica de Uso',
    suscriberAgreement: 'Acuerdo de Suscriptor',
    networkManagement: 'Manejo de Red',

    // Sweet alert
    success: '¡Bien!',
    paymentSuccessMsg: 'Su pago ha sido procesado',
    savePaymentMethodSuccessMsg: 'Su nuevo método de pago ha sido guardado',
    createTicketSuccessMsg: 'Su nuevo ticket ha sido creado',
    updatePasswordSuccessMsg: 'Su nueva contraseña ha sido actualizada',
    forgotPasswordSuccessMsg: 'Su código de verificación fue enviado al correo electrónico: ',
    resetPasswordSuccessMsg: 'Su contraseña fue cambiada exitosamente',
    wrongCredentialsMsg: 'Nombre de usuario o contraseña incorrecta',
    unAuthorizedMsg: 'Usted no esta autorizado en esta plataforma',

    // Success Messages
    paymentCompleted: '¡Su pago fue realizado!',
    paymentCompletedSubheading: 'Si usted tiene dudas sobre este pago, por favor comuníquese con servicio al cliente.',
    completedPaymentInformation: 'Información de pago realizado',
    paidAmount: 'Cantidad Pagada',
    transactionId: "Número de Transacción",
    accountBalance: 'Balance de Cuenta',

    // Error Messages
    paymentError: '¡Ha habido un error realizando su pago!',
    paymentErrorSubheading: 'Para mas información comuníquese con servicio al cliente.',
    errorMsg: 'Mensaje de error',

//     Suspended Message
    suspendedAlert: 'Parecería haber un problema con su cuenta. Por favor haga login para mas información.\n' +
        'También puede comunicarse con nuestro departamento de servicio al cliente al 787-957-6000.',

    captiveSubscriberHeader:'¡Bienvenido a Optico Fiber!',
    captiveSubscriberSubHeader1:'Para suscribirse haga clíc',
    captiveSubscriberSubHeader2:' o llame al 787-957-6000',

};

export default es;