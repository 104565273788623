<template>
  <v-container class="width50">
    <invoice-information
    @loading="loading"
    @error-message="showError"
    />
  </v-container>
</template>

<script>
// importing child components
import InvoiceInformation from '@/components/BillingInformation/Invoices/InvoiceInformation.vue'

export default {
  name: 'invoice-',
  // child components that are being displayed
  components: {
    InvoiceInformation
  },
  methods: {
    // showing error message component with message received
    showError(msg) {
      this.$emit('error-message', msg);
    },
    // showing loading component based on the value recieved (true or false)
    loading(val) {
      this.$emit('loading', val)
    }
  }
}
</script>
