<template>
    <v-dialog
    v-model="isOpen"
    width="600px"
    persistent
    >
        <v-card>
            <v-card-title class="text-h5">
                {{$t('createTicketBtn')}}
            </v-card-title>
            <v-card-text>
                <v-form 
                ref="form" 
                lazy-validation>
                    <v-container>
                        <v-row>
                            <v-col
                            cols="12"
                            sm="12"
                            >
                                <v-text-field
                                v-model="ticket.subject"
                                label="Subject"
                                :rules="required"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                            cols="12"
                            sm="6"
                            >
                                <v-text-field
                                v-model="ticket.cc"
                                label="Cc"
                                ></v-text-field>
                            </v-col>
                             <v-col
                            cols="12"
                            sm="6"
                            >
                                <v-file-input
                                v-model="ticket.attachment"
                                label="Attachment"
                                ></v-file-input>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                            cols="12"
                            sm="12"
                            >
                                <v-textarea
                                v-model="ticket.msg"
                                label="Message"
                                :rules="required"
                                outlined
                                ></v-textarea>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
            color="error"
            text
            @click="closeDialog"
            >
                {{$t('closeBtn')}}
            </v-btn>
            <v-btn
            color="primary"
            text
            @click="onCreateTicket"
            >
                {{$t('createBtn')}}
            </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>

export default ({
    name: 'create-ticket-dialog',
    // data passed from parent component
    props: ['showDialog'],
    // initializing data
    data: () => ({
        ticket: {
            cc: '',
            subject: '',
            attachment: null,
            msg: '',
        },
        required: [value => !!value || this.$i18n.t('requiredRule')],
    }),
    computed: {
        isOpen: {
            get () {
                return this.showDialog
            },
            set (value) {
                this.$emit('input', value)
            }
        },
    },
    methods: {
        // closing dialog when this event is emitted
        closeDialog() {
            this.resetForm();
            this.$emit('closeDialog', false);
        },
        // creates a ticket in the RT ticket system
        onCreateTicket(){
            if(this.$refs.form.validate()) {
                this.resetForm();
                this.$emit('closeDialog', false);
            }
        },
        // resetting the create ticket form
        resetForm(){
            this.$refs.form.reset();
            this.$refs.form.resetValidation();
        }
    }
})
</script>
