<template>
    <v-container v-if="card_info.name">
        <v-card-text>
            <div class="font16"><strong>{{ $t("savedCard") }}</strong></div>
                <v-row>
                    <v-col
                    cols="6">
                        <div class="my-5" v-if="card_info.name">
                            <div>
                                <span>{{$t('accountName')}}</span>
                            </div>
                            <div class="font16">
                                <strong>{{card_info.name}}</strong>
                            </div>
                        </div>

                        <div class="my-5" v-if="card_info.account_number">
                            <div>
                                <span>{{$t('accountNumber')}}</span>
                            </div>
                            <div class="font16">
                                <strong>{{card_info.account_number}}</strong>
                            </div>
                        </div>
                        <div class="my-5" v-else-if="card_info.number">
                            <div>
                                <span>{{$t('cardNumber')}}</span>
                            </div>
                            <div class="font16">
                                <strong>{{card_info.number}}</strong>
                            </div>
                        </div>

                        <div class="my-5" v-if="card_info.bank_number">
                            <div>
                                <span>{{$t('routingNumber')}}</span>
                            </div>
                            <div class="font16">
                                <strong>{{card_info.bank_number}}</strong>
                            </div>
                        </div>
                        <div class="my-5" v-else-if="card_info.exp_date">
                            <div>
                                <span>{{$t('expDate')}}</span>
                            </div>
                            <div class="font16">
                                <strong>{{card_info.exp_date}}</strong>
                            </div>
                        </div>

                        <div class="my-5" v-if="card_info.payment_method">
                            <div>
                                <span>{{$t('paymentMethod')}}</span>
                            </div>
                            <div class="font16">
                                <strong>{{card_info.payment_method}}</strong>
                            </div>
                        </div>
                    </v-col>

                    <v-col
                    cols="6">
                        <div class="my-5" v-if="card_info.address">
                            <div>
                                <span>{{$t('billingAddress')}}</span>
                            </div>
                            <div class="font16">
                                <strong>{{card_info.address}}</strong>
                            </div>
                        </div>

                        <div class="my-5" v-if="card_info.city">
                            <div>
                                <span>{{$t('city')}}</span>
                            </div>
                            <div class="font16">
                                <strong>{{card_info.city}}</strong>
                            </div>
                        </div>

                        <div class="my-5" v-if="card_info.iso_3166_1_a2">
                            <div>
                                <span>{{$t('state')}}</span>
                            </div>
                            <div class="font16">
                                <strong>{{card_info.iso_3166_1_a2}}</strong>
                            </div>
                        </div>

                        <div class="my-5" v-if="card_info.zip">
                            <div>
                                <span>{{$t('zip')}}</span>
                            </div>
                            <div class="font16">
                                <strong>{{card_info.zip}}</strong>
                            </div>
                        </div>

                        <div class="my-5" v-if="card_info.phone_number">
                            <div>
                                <span>{{$t('billingPhoneNumber')}}</span>
                            </div>
                            <div class="font16">
                                <strong>{{card_info.phone_number}}</strong>
                            </div>
                        </div>
                    </v-col>
                </v-row>
        </v-card-text>
    </v-container>
</template>


<script>

export default ({
    name: 'saved-payment-method-card',
    // data received from parent component
    props: ['card_info'],
})
</script>
