<template>
  <div id="app">
    <v-app-bar
    color="#FFFFFF"
    elevation="1"
    >
      <v-spacer></v-spacer>
      
      <v-img
      class="mx-auto"
      :src="require('@/assets/logos/optico_fiber.png')" 
      max-height="100"
      max-width="100"
      ></v-img>

      <v-spacer></v-spacer>

      <v-btn 
      icon
      @click="changeLocale(languages.locale)">
        {{ languages.locale }}
      </v-btn>

      <v-menu open-on-hover offset-y v-if="!$route.path.includes('login') && !$route.path.includes('forgotPassword') && !$route.path.includes('captive')">
        <template v-slot:activator="{ on, attrs }">
          <v-btn text v-bind="attrs" v-on="on">
            <v-icon>mdi-account</v-icon>
            {{customerName()}}
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item router to="/profile">
            <v-list-item-action>
              <v-list-item-title>{{$t('profile')}}</v-list-item-title>
            </v-list-item-action>
          </v-list-item>
          <v-list-item router @click="logout">
            <v-list-item-action>
              <v-list-item-title>{{$t('logout')}}</v-list-item-title>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-menu>

      <template v-slot:extension v-if="!$route.path.includes('login') && !$route.path.includes('forgotPassword') && !$route.path.includes('captive')">
        <v-tabs
        align-with-title
        show-arrows
        >
          <v-tabs-slider></v-tabs-slider>
          <v-tab 
          style="size: 5px"
          to="dashboard"
          link
          align-with-title
          > 
            {{ $t('dashboard') }}
          </v-tab>

          <v-tab 
          style="size: 5px"
          to="billing"
          link
          align-with-title
          > 
            {{ $t('billingInfo') }}
          </v-tab>

          <v-tab 
          v-if="!isCorporativeCustomer()"
          style="size: 5px"
          to="services"
          link
          align-with-title
          > 
            {{ $t('services') }}
          </v-tab>
        </v-tabs>
      </template>
    </v-app-bar>
  </div>
</template>

<script>
// importing internationalization variables
import i18n from '@/plugins/i18n';
// importing moment formatter
import moment from 'moment'
// importing moment languages
import spanishLocale from 'moment/locale/es';
import englishLocale from "moment/locale/en-gb";

export default {
  // initializing the data
  name: "nav-bar",
  data: () => ({
    // setting the languages
    languages: { locale: i18n.fallbackLocale },
  }),
  methods: {
    // checking if current customer is corporative
    isCorporativeCustomer() {
      return this.$store.getters.isCorporativeCustomer;
    },
    customerName() {
      return this.$store.getters.StateUser ? this.$store.getters.StateUser.name : '';
    },
    // method to change the page languague when the button is 
    // pressed
    changeLocale(locale) {
      // if the current languague is english, the button text has to be set to
      // spanish and the dates formats to english. The same process runs when
      // current language is spanish
      this.languages.locale = (locale === 'en') ? 'es' : 'en';
      moment.updateLocale(locale, (locale === 'en') ? englishLocale : spanishLocale);
      i18n.locale = locale;
    },
    // method that is in charge of doing the process when the logout button is pressed
    async logout() {
      // the logout action method
      await this.$store.dispatch('logout')
    }
  }
};
</script>
