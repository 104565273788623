<template>
    <v-card
    elevation="1"
    class="width75 mx-auto my-5">
        <v-card-title class="font25">{{$t('personalInfo')}}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
            <v-row>
                <v-col cols="6"> 
                    <div class="my-5">
                        <div>
                            <span>{{$t('name')}}</span>
                        </div>
                        <div class="font16">
                             <strong>{{customer_information.name}}</strong>
                        </div>
                    </div>

                    <div class="my-5" v-if="customer_information.companyname">
                        <div>
                            <span>{{$t('companyName')}}</span>
                        </div>
                        <div class="font16">
                             <strong>{{customer_information.companyname}}</strong>
                        </div>
                    </div>

                    <div class="my-5">
                        <div>
                            <span>{{$t('firstname')}}</span>
                        </div>
                        <div class="font16">
                             <strong>{{customer_information.firstname}}</strong>
                        </div>
                    </div>

                    <div class="my-5" v-if="customer_information.midinit">
                        <div>
                            <span>{{$t('midi')}}</span>
                        </div>
                        <div class="font16">
                             <strong>{{customer_information.midinit}}</strong>
                        </div>
                    </div>

                    <div class="my-5">
                        <div>
                            <span>{{$t('lastname')}}</span>
                        </div>
                        <div class="font16">
                             <strong>{{customer_information.lastname}}</strong>
                        </div>
                    </div>
                </v-col>

                <v-col cols="6"> 
                    <div class="my-5">
                        <div>
                            <span>{{$t('address1')}}</span>
                        </div>
                        <div class="font16">
                            <strong>{{customer_information.baddr1}}</strong>
                        </div>
                    </div>

                    <div class="my-5" v-if="customer_information.address_line_2">
                        <div>
                            <span>{{$t('address2')}}</span>
                        </div>
                        <div class="font16">
                             <strong>{{customer_information.address_line_2}}</strong>
                        </div>
                    </div>

                    <div class="my-5">
                        <div>
                            <span>{{$t('city')}}</span>
                        </div>
                        <div class="font16">
                             <strong>{{customer_information.city}}</strong>
                        </div>
                    </div>

                    <div class="my-5">
                        <div>
                            <span>{{$t('state')}}</span>
                        </div>
                        <div class="font16">
                             <strong>{{customer_information.state}}</strong>
                        </div>
                    </div>

                    <div class="my-5">
                        <div>
                            <span>{{$t('zip')}}</span>
                        </div>
                        <div class="font16">
                             <strong>{{customer_information.zip}}</strong>
                        </div>
                    </div>

                </v-col>
            </v-row>
        </v-card-text>

    </v-card>
</template>

<script>

export default {
    // getting the personal information from the parent component
    props: ['customer_information'],
}
</script>