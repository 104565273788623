<template>
    <v-card elevation="1" style="height: 100%;"> 
        <v-card-title>{{$t('serviceStatus')}}</v-card-title>
        <v-card-text>
            <div v-if="hasServices">
                <v-row>
                    <v-col cols="6">
                        <div v-if="services.internetService">
                            <div v-for="service in services.internetService" v-bind:key="service.id">
                                <div class="my-5" v-if="service.pppoe ? true :  false">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-avatar
                                            size="32px"
                                            >
                                                <v-img
                                                v-bind="attrs" 
                                                v-on="on"
                                                :src="require('@/assets/icons/Icon_world_128.png')" 
                                                ></v-img>
                                            </v-avatar>
                                        </template>
                                        <span>{{service.product_visible_name}}</span>
                                    </v-tooltip>
                                    <v-icon md class="ml-5" color="green">mdi-checkbox-marked-circle</v-icon> 
                                    {{$t('active')}}
                                </div>
                                <div class="my-5" v-else>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-avatar
                                            size="32px"
                                            >
                                                <v-img
                                                v-bind="attrs" 
                                                v-on="on"
                                                :src="require('@/assets/icons/Icon_world_128.png')" 
                                                ></v-img>
                                            </v-avatar>
                                        </template>
                                        <span>{{service.product_visible_name}}</span>
                                    </v-tooltip>
                                    <v-icon md class="ml-5" color="red">mdi-close</v-icon> 
                                    {{$t('inactive')}}
                                </div>
                            </div>
                        </div>
                    </v-col>

                    <v-col cols="6">
                        <div v-if="services.phoneService">
                            <div v-for="service in services.phoneService" v-bind:key="service.id">
                                <div class="my-5" v-if="service.sip_status">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon v-bind="attrs" v-on="on" md color="grey darken-2">mdi-phone-in-talk</v-icon> 
                                        </template>
                                        <span>{{service.product_visible_name}}</span>
                                    </v-tooltip>
                                    <v-icon md class="ml-5" color="green">mdi-checkbox-marked-circle</v-icon> 
                                    {{$t('active')}}
                                </div>
                                <div class="my-5" v-else>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon v-bind="attrs" v-on="on" md color="grey darken-2">mdi-phone-in-talk</v-icon> 
                                        </template>
                                        <span>{{service.product_visible_name}}</span>
                                    </v-tooltip>
                                    <v-icon md class="ml-5" color="red">mdi-close</v-icon> 
                                    {{$t('inactive')}}
                                </div>
                            </div>
                        </div>


                    </v-col>
                </v-row>
            </div>

            <div v-else class="my-8 center">
                {{$t('noService')}}
            </div>
        </v-card-text>
    </v-card>
</template>

<script>

export default ({
    // receiving the services data from parent component
    props: ['services'],
    data: () => ({
      hasServices: false,
    }),
    watch: {
      services() {
        if(this.services.internetService || this.services.phoneService || this.services.emailService || this.services.otherService)
          this.hasServices = true;
      }

    },
})
</script>
