<template>
  <v-container class="width50">
    <payment-method 
    @loading="loading"
    @error-message="showError"
    @reRenderComponent="reRenderComponent"
    :key="componentKey"
    />
  </v-container>
</template>

<script>
// importing child components
import PaymentMethod from '@/components/BillingInformation/PaymentInformation/PaymentMethod.vue'

export default {
  name: 'payment-information',
  // child components that are being displayed
  components: {
    PaymentMethod
  },
  data: () => ({
    componentKey: 0,
  }),
  methods: {
    // showing error message component with message received
    showError(msg) {
      this.$emit('error-message', msg)
    },
    // showing loading component based on the value recieved (true or false)
    loading(val){
      this.$emit('loading', val);
    },
    reRenderComponent(value) {
      this.componentKey += value;
    }
  }
}
</script>
