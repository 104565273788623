<template>
  <v-card elevation="1">
    <v-card-title>{{ $t('monthlyTransaction') }}</v-card-title>
    <v-row>
      <v-col cols="12" lg="12">
        <v-card-text>
          <v-row class="font20 pa-0 mx-4">
            <v-col class="px-0">
              {{ $t('payments') }}
            </v-col>
            <v-col style="text-align: end" class="px-0">
              {{ formatToCurrency(payments) }}
            </v-col>
          </v-row>
          <v-row class="my-5 font20 pa-0 mx-4">
            <v-col class="px-0">
              {{ $t('adjustments') }}
            </v-col>
            <v-col style="text-align: end" class="px-0">
              {{ formatToCurrency(adjustments) }}
            </v-col>
          </v-row>
        </v-card-text>
      </v-col>
    </v-row>
    <br>
  </v-card>
</template>

<script>

export default {
  // receiving the current balance and the new balance data from parent component
  props: ['balance', 'payments', 'adjustments'],
  methods: {
    // formatting the currency input data
    formatToCurrency(variable) {
      return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD'
          }
      ).format(variable);
    },
  }
}
</script>
