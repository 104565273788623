<template>
  <div>
    <payment-completed-card
    v-bind:paymentReceipt="paymentReceipt"
    v-bind:paymentInformation="paymentInformation"
    ></payment-completed-card>
  </div>
</template>

<script>
// importing child components
import PaymentCompletedCard from '@/components/Cards/PaymentCompletedCard.vue';

export default {
  name: 'payment-completed',
  props: ['paymentReceipt', 'paymentInformation'],
  // child components that are being displayed
  components: {
    PaymentCompletedCard
  },
};
</script>