<template>
  <div>
    <v-container class="width50">
      <payment-stepper 
      @loading="loading"
      @error-message="showError"
      />
    </v-container>
  </div>
</template>

<script>
// importing child components
import PaymentStepper from '@/components/BillingInformation/MakePayment/PaymentStepper.vue'

export default {
  name: 'make-payment',
  // child components that are being displayed
  components: {
    PaymentStepper
  },
  methods: {
    // showing error message component with message received
    showError(msg) {
      this.$emit('error-message', msg);
    },
    // showing loading component based on the value recieved (true or false)
    loading(val) {
      this.$emit('loading', val);
    }
  }
}
</script>
