<template>
   <div>
      <v-overlay
      :value="loading"
      >
         <v-progress-circular indeterminate size="100">
         {{$t('loadingBtn')}}
         </v-progress-circular>
      </v-overlay>
      <v-container style="margin-top: 150px;">
         <v-layout justify-center>
            <v-flex xs12 sm8 md4>
               <forgot-password-card
               @loading="val => loading = val"
               @error-message="showError"
               />
            </v-flex>
         </v-layout>
      </v-container>
   </div>
</template>

<script>
// importing child components
import ForgotPasswordCard from '@/components/ResetPassword/ForgotPasswordCard.vue';

export default {
   // child components that are being displayed
   components: {
      ForgotPasswordCard
   },
   // initializing the data
   data: () => ({
      loading: false,
   }),
   methods: {
      // showing the error message component with the message passed
      showError(msg) {
         this.$toast.error(msg, {
            timeout: 4000
         });
      }
   }
};
</script>