import Vue from 'vue'
import VueRouter from 'vue-router'
// importing views
import Login from '../views/Login.vue'
import Dashboard from '../views/Dashboard.vue'
import MyProfile from '../views/Profile.vue'
import Billing from '../views/BillingInformation.vue'
import Tickets from '../views/Tickets.vue'
import Services from '../views/Services.vue'
import ForgotPassword from '../views/ForgotPassword.vue'
import PaymentCompleted from '../views/PaymentCompleted.vue'
// importing components
import NotFound from '../components/NotFound.vue'
import PaymentError from '../components/Cards/PaymentErrorCard.vue'
// importing modules
import store from '@/store/modules';

// using vue router for the routes
Vue.use(VueRouter)

// initializing all routes
const routes = [
    {
        path: "*",
        name: 'Not Found',
        component: NotFound
    },
    {
        path: "/",
        redirect: "/login"
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        props: true
    },
    {
        path: '/paymentCompleted',
        name: 'Payment Completed',
        component: PaymentCompleted,
        props: true,
    },
    {
        path: '/paymentError',
        name: 'Payment Error',
        component: PaymentError,
        props: true,
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard,
        meta: {requiresAuth: true},
    },
    {
        path: '/profile',
        name: 'My Profile',
        component: MyProfile,
        meta: {requiresAuth: true},
    },
    {
        path: '/billing',
        name: 'Billing Information',
        component: Billing,
        meta: {requiresAuth: true},
    },
    {
        path: '/tickets',
        name: 'Trouble Tickets',
        component: Tickets,
        meta: {requiresAuth: true},
    },
    {
        path: '/services',
        name: 'Services',
        component: Services,
        meta: {requiresAuth: true},
    },
    {
        path: '/forgotPassword',
        name: 'Forgot Password',
        component: ForgotPassword,
    },
    {
        path: "/captive",
        name: "Captive",
        component: () => import("../views/CaptiveView.vue"),
    },
    {
        path: "/captive/subscribe",
        name: "Captive Subscribe",
        component: () => import("../views/CaptiveToSubscribe.vue"),
    },
];

const router = new VueRouter({
    base: '/',
    mode: 'history', // remove # from url
    routes
})

// this is where the system controlls when to let the customer can enter the 
// application and when not
router.beforeEach((to, from, next) => {
    var now = new Date();
    // checks if the to route name is Forgot Passsword to let the
    // customer go to the next route
    if (to.name === 'Forgot Password' || to.name === 'Captive' || to.name === 'Captive Subscribe') {
        next()
        return
    }
    // checks if a user is logged in, if not it will redirect to login route
    if (!store.getters.isAuthenticated && to.name !== 'Login') {
        next('/login')
        return
    }
    // checks if session is expired, if it is, it will redirect to login
    if (now.getTime() > store.getters.expires_at && to.name !== 'Login') {
        store.dispatch('logout')
        next('/login')
        return
    }
    // checks if customer is authenticated, if it is, it will redirect to dashboard
    if (store.getters.isAuthenticated && to.name === 'Login') {
        next('/dashboard')
        return
    }
    // if none of the if statements are full filled, customer is going to next route
    next()
})

export default router
