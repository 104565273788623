<template>
  <div class="ma-1">
    <v-row>
      <v-col cols="12">
        <v-btn
        v-if="showSavedPaymentMethodBtn"
        color="default"
        outlined
        width="100%"
        height="150px"
        @click="nextStep(1)"
        >
          <v-icon>mdi-credit-card</v-icon>
          <div class="font16">{{ saved_payment_method }}</div>
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-btn
        color="default"
        outlined
        block
        width="100%"
        height="50px"
        @click="showPaymentMethods = !showPaymentMethods"
        >  
          <v-icon>mdi-plus</v-icon>
          <div class="font16">{{ $t("newPayment") }}</div>
        </v-btn>
      </v-col>
    </v-row>

    <div v-if="showPaymentMethods">
      <v-radio-group v-model="paymentTypesSelectedItem">
        <v-radio
        v-for="item in paymentTypes"
        :key="item.key"
        :label="$t(item.text)"
        :value="item.value"
        :name="item.value"
        @click="onChangePaymentType()"
        ></v-radio>
      </v-radio-group>

      <v-card elevation="0">
        <v-card-text>
          <v-form ref="form" lazy-validation>
            <div v-if="showCardPaymentForm">
              <div class="font16"><strong>{{ $t("payWithCard") }}</strong></div>
              <v-row>
                <v-col :cols="12 / 10">
                  <v-text-field
                  v-model="new_payment_method.cardholder_name"
                  :rules="required"
                  :error-messages="errorMessages.cardholder_name"
                  :label="$t('cardholderName')"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col :cols="12 / 10" md="6">
                  <v-text-field
                  v-model="new_payment_method.credit_card_number_formatted"
                  :label="$t('cardNumber')"
                  :rules="cardNoRules"
                  :error-messages="errorMessages.credit_card_number"
                  :maxlength="cardNumberMaxLength"
                  :value="ccFormat(new_payment_method.credit_card_number_formatted)"
                  ></v-text-field>
                </v-col>
                <v-col :cols="12 / 10" md="3">
                  <v-text-field
                  v-model="new_payment_method.cvv"
                  :label="$t('cvv')"
                  type="password"
                  :rules="cvvRules"
                ></v-text-field>
                </v-col>
                <v-col :cols="12 / 10" md="3">
                  <v-text-field
                  v-model="new_payment_method.exp_date"
                  maxlength="7"
                  :label="$t('expDate')"
                  :rules="expDateRules"
                  :value="expDateFormat(new_payment_method.exp_date)"
                  ></v-text-field>
                </v-col>
              </v-row>
            </div>

            <div v-if="showAddressPaymentForm">
              <div class="font16 mt-8"><strong>{{ $t("billingAddress") }}</strong></div>
              <v-row>
                <v-col :cols="12 / 10" md="6">
                  <v-text-field
                  v-model="new_payment_method.address1"
                  :label="$t('address1')"
                  :rules="required"
                  :error-messages="errorMessages.address1"
                  ></v-text-field>
                </v-col>
                <v-col :cols="12 / 10" md="6">
                  <v-text-field
                  v-model="new_payment_method.address2"
                  :label="$t('address2')"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col :cols="12 / 10" md="4">
                  <v-text-field
                  v-model="new_payment_method.city"
                  :label="$t('city')"
                  :rules="required"
                  :error-messages="errorMessages.city"
                  ></v-text-field>
                </v-col>
                <v-col :cols="12 / 10" md="4">
                  <v-select
                  v-model="new_payment_method.iso_3166_1_a2"
                  :label="$t('state')"
                  :items="states" 
                  value="value" 
                  item-text="state"
                  :error-messages="errorMessages.iso_3166_1_a2"
                  :rules="required"
                  >
                  </v-select>
                </v-col>
                <v-col :cols="12 / 10" md="4">
                  <v-text-field
                  v-model="new_payment_method.zip"
                  :label="$t('zip')"
                  :error-messages="errorMessages.zip"
                  :rules="zipRules"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col :cols="12 / 10" md="4">
                  <v-text-field
                  v-model="new_payment_method.phone_number"
                  :label="$t('billingPhoneNumber')"
                  :error-messages="errorMessages.phone_number"
                  :rules="required"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col :cols="12 / 10" md="6">
                  <v-checkbox
                  v-model="new_payment_method.setDefaultCard"
                  :label="$t('setCardAsDefault')"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </div>

    <v-btn
    v-if="showPaymentMethods"
    class="mx-2"
    color="primary"
    block
    @click="nextStepValidate(1)"
    >
      {{ $t("continueBtn") }}
    </v-btn>
  </div>
</template>

<script>
// importing states 
import states from '@/plugins/states'
// importing Api requests
import Api from '@/api/Api'

export default {
  name: "stepper-two",
  // data received from parent component
  props: ['saved_payment_method', 'showSavedPaymentMethodBtn'],
  // initializing data
  data: function() {
    return {
      showPaymentMethods: false,
      showCardPaymentForm: true,
      showAddressPaymentForm: true,
      cardNumberMaxLength: 19,
      states: states,
      new_payment_method: {
        cardholder_name: "",
        credit_card_number_formatted: "",
        credit_card_number: "",
        cvv: "",
        exp_date: "",
        address1: "",
        address2: "",
        iso_3166_1_a2: "PR",
        city: "",
        zip: "",
        phone_number: "",
        setDefaultCard: false,
      },
      paymentTypesSelectedItem: "card",
      paymentTypes: [
        { key: 1, text: "card", value: "card" },
        { key: 2, text: "paypal", value: "PayPal" },
      ],
      cardNoRules: [
        value => !!value && (value.length === this.cardNumberMaxLength) || this.$i18n.t('ccNumberRule') + this.cardNumberMaxLength + this.$i18n.t('ccNumberRule2'),
      ],
      cvvRules: [
        value => !!value && (value.length >= 3 && value.length <= 4) || this.$i18n.t('ccvRule'),
      ],
      zipRules: [
        value => !!value && value.length === 5 || this.$i18n.t('zipRule'),
      ],
      expDateRules: [
        value => /^[0-9]{4}-[0-9]{2}$/.test(value) || this.$i18n.t('expDateFormatRule'),
        value => value >= new Date().toISOString().substr(0, 7) || this.$i18n.t('expDateRule'),
      ],
      required: [
        value => !!value || this.$i18n.t('requiredRule'),
      ],
      errorMessages: {
        cardholder_name: [],
        credit_card_number: [],
        cvv: [],
        exp_date: [],
        address1: [],
        iso_3166_1_a2: [],
        city: [],
        zip: [],
        phone_number: [],
      }
    }
  },
  methods: {
    // Formats the exp date into YYYY-MM
    expDateFormat(date) {
      if(!date) return;
      let value = date.replace(/\D/g, '')
      if((/^\d{0,7}$/).test(value)) this.new_payment_method.exp_date = value.replace(/(\d{4})/, '$1-');
    },
    // emitting next step event to parent component
    nextStep(n) {
      this.$emit("next-step", n);
      this.$emit("payment_method", null);
      this.$emit("payment_type", 'saved');
    },
    // emitting next step event to parent component but with the new payment method 
    // information
    async nextStepValidate(n) {
      try {
        // validates if all input fields passed the validation
        if (this.$refs.form.validate()) {
          if (this.paymentTypesSelectedItem === "card") {
            let form_data = new FormData();
            // appending all new payment information to the form data
            for (let key in this.new_payment_method) form_data.append(key, this.new_payment_method[key]);

            // validating with api if the credit card information is correct
            let response = await Api.validateCreditCardInformation(this.$store.getters.token, form_data);
            
            // if validation went well then all data is passed to the next step
            if(response.status === 200) {
              this.$emit("next-step", n);
              this.$emit("payment_method", this.new_payment_method);
              this.$emit("payment_type", this.paymentTypesSelectedItem);
            }
          } else {
            this.$emit("next-step", n);
            this.$emit("payment_method", this.new_payment_method);
            this.$emit("payment_type", this.paymentTypesSelectedItem);
          }
        }
      } catch (error) {
        if(error.response.status === 422) {
          let errors = error.response.data.errors;
          for (let field in errors) this.errorMessages[field] = errors[field]
        } else if (error.response) {
          this.$emit('error-message', error.response.data.message);
        }
      }
    },
    // event when payment type is changed from paypal to credit card and viseversa
    onChangePaymentType() {
      this.resetForm();
      if (this.paymentTypesSelectedItem === "PayPal") {
        this.showCardPaymentForm = false;
        this.showAddressPaymentForm = false;
      } else {
        this.showCardPaymentForm = true;
        this.showAddressPaymentForm = true;
      }
    },
    // resetting the new credit card form
    resetForm() {
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },
    // formatting the credit card input field based on the credit card 2 first digits
    ccFormat(number) {
      if (!number) return;
      this.new_payment_method.credit_card_number = this.new_payment_method.credit_card_number_formatted.split(" ").join('');
      this.new_payment_method.credit_card_number_formatted = number;
      let value = this.new_payment_method.credit_card_number_formatted.replace(/\D/g, "");
      if (/^3[47]\d{0,13}$/.test(value)) {
        // american express, 15 digits
        this.new_payment_method.credit_card_number_formatted = value
          .replace(/(\d{4})/, "$1 ")
          .replace(/(\d{4}) (\d{6})/, "$1 $2 ");
        this.cardNumberMaxLength = 17;
      } else if (/^\d{0,16}$/.test(value)) {
        // regular cc number, 16 digits
        this.new_payment_method.credit_card_number_formatted = value
          .replace(/(\d{4})/, "$1 ")
          .replace(/(\d{4}) (\d{4})/, "$1 $2 ")
          .replace(/(\d{4}) (\d{4}) (\d{4})/, "$1 $2 $3 ");
        this.cardNumberMaxLength = 19;
      }
    },
  },
};
</script>
