import axios from 'axios';

/**
 * Most requests need to include the token for authentication, if a request via axios does not inclide the token
 * in the headers, the backend (API) will not respond with the data requested.
 * Most requests need to include the i_customer to gather the customer's requested information.
 *
 * - Some methods (like validateCustomer) do not need a token because there is no customer authenticated yet.
 * - Some methods do not need a i_customer passed as params.
 * - Some methods required additional params, it varies depending on what is requested.
 */

export default {
    // Makes a request via axios to validate the customer credentials
    async validateCustomer(user_credentials) {
        const response = await axios.post('validateCustomer', user_credentials, {
            headers: {
                'content-type': 'multipart/form-data',
            }
        });
        return response;
    },
    // Makes a request via axios to get the customer's contact information
    async getCustomerContactInformation(i_customer, token) {
        const response = await axios.get('getCustomerContact', {
            params: {
                i_customer: i_customer
            },
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response.data;
    },
    // Makes a request via axios to get the customer payment information
    async getCustomerPaymentInformation(i_customer, token) {
        const response = await axios.get('getCustomerPaymentInformation', {
            params: {
                i_customer: i_customer
            },
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response.data;
    },
    // Makes a request via axios to get the list of the customer RT Ticket
    async getCustomerRtTicketList(i_customer, token) {
        const response = await axios.get('getCustomerRtTicketList', {
            params: {
                i_customer: i_customer
            },
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response.data;
    },
    // Makes a request via axios to get the customer last invoice
    async getCustomerLastInvoice(i_customer, token) {
        const response = await axios.get('getCustomerLastInvoice', {
            params: {
                i_customer: i_customer
            },
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response.data;
    },
    // Makes a request via axios to get the customer invoice list
    async getCustomerInvoiceList(i_customer, token) {
        const response = await axios.get('getCustomerInvoiceList', {
            params: {
                i_customer: i_customer
            },
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response.data;
    },
    // Makes a request via axios to get a specific invoice pdf
    async getCustomerInvoicePdf(i_customer, i_invoice, token) {
        const response = await axios.get('getCustomerInvoicePdf', {
            params: {
                i_customer: i_customer,
                i_invoice: i_invoice,
            },
            headers: {
                Authorization: `Bearer ${token}`
            },
            responseType: 'blob',
        });
        return response.data;
    },
    // Makes a request via axios to get the customer account xdrs
    async getCustomerAccountXdrs(i_customer, token, from_date, to_date, service = null) {
        const response = await axios.get('getCustomerAccountXdr', {
            params: {
                i_customer: i_customer,
                from_date: from_date,
                to_date: to_date,
                i_service: service,
            },
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response.data;
    },
    // Makes a request via axios to get the customer account voice calls
    async getCustomerVoiceCalls(i_customer, token, from, until, accounts) {
        const response = await axios.get('getCustomerVoiceCalls', {
            params: {
                i_customer: i_customer,
                from: from,
                until: until,
                accounts: accounts,
            },
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response;
    },
    // Makes a request via axios to get the customer payment method registered in porta
    async changeCustomerPaymentMethod(i_customer, token, form_data) {
        const response = await axios.post('changeCustomerPaymentMethod', form_data, {
            params: {
                i_customer: i_customer,
            },
            headers: {
                Authorization: `Bearer ${token}`,
                'content-type': 'multipart/form-data',
            }
        });
        return response;
    },
    // Makes a request via axios to change the customer current password in porta
    async changeCustomerPassword(i_customer, token, form_data) {
        const response = await axios.post('changeCustomerPassword', form_data, {
            params: {
                i_customer: i_customer,
            },
            headers: {
                Authorization: `Bearer ${token}`,
                'content-type': 'multipart/form-data',
            }
        });
        return response;
    },
    // Makes a request via axios to make a payment in porta
    async makeCustomerPayment(i_customer, token, form_data) {
        const response = await axios.post('makeCustomerPayment', form_data, {
            params: {
                i_customer: i_customer,
            },
            headers: {
                Authorization: `Bearer ${token}`,
                'content-type': 'multipart/form-data',
            }
        });
        return response;
    },
    // Makes a request via axios to send an email with a verification code so that the customer
    // can reset the current password
    async customerForgotPassword(login) {
        return await axios.post('customerForgotPassword', {login: login});
    },
    // Makes a request via axios to validate the verification code and change the password
    // in porta
    async customerResetPassword(form_data) {
        const response = await axios.post('customerResetPassword', form_data, {
            headers: {
                'content-type': 'multipart/form-data',
            }
        });
        return response;
    },
    // Makes a request via axios to register a transaction with paypal in porta
    async registerTransaction(i_customer, token, form_data) {
        const response = await axios.post('registerTransaction', form_data, {
            params: {
                i_customer: i_customer,
            },
            headers: {
                Authorization: `Bearer ${token}`,
                'content-type': 'multipart/form-data',
            }
        });
        return response;
    },
    // Makes a request via axios to get the customer account list
    async getCustomerAccountList(i_customer, token) {
        const response = await axios.get('getCustomerAccountList', {
            params: {
                i_customer: i_customer,
            },
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response.data;
    },
    // Makes a request via axios to get a specific xdr information
    async getXdrInfo(xdr_id, token) {
        const response = await axios.get('getXdrInfo', {
            params: {
                xdr_id: xdr_id,
            },
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response.data;
    },
    // Makes a request via axios to validate the information of a credit card
    async validateCreditCardInformation(token, form_data) {
        const response = await axios.post('validateCreditCardInformation', form_data, {
            headers: {
                Authorization: `Bearer ${token}`,
                'content-type': 'multipart/form-data',
            }
        });
        return response;
    },
    checkUpgradeXgs(token, iCustomer) {
        return axios.post('xgsUpgrade', {
            i_customer: iCustomer
        }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    },
    confirmationUpgradeXgs(token, data) {
        return axios.post('saveConfirmationXgs', data, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    },

    checkSsn(token, iCustomer) {
        return axios.post('checkSsn', {
            i_customer: iCustomer
        }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    },
    resetAwsPassword(token, form_data){
        return axios.post('resetAwsPassword', form_data, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    },
    saveSsn(token, form_data) {
        return axios.post('saveSsn', form_data, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    },
    // Makes a request via axios to get the customer current balance
    async getCustomerCurrentBalance(i_customer, token) {
        const response = await axios.get('getCustomerCurrentBalance', {
            params: {
                i_customer: i_customer
            },
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response.data;
    },
}
