<template>
    <v-container class="width50">
      <h3 class="display-5">{{ $t('paymentCompleted') }}</h3>

      <span class="subheading">{{ $t('paymentCompletedSubheading') }}</span>

      <v-divider class="my-3"></v-divider>
      <div class="mt-8">
        <v-row>
          <v-col cols="12" sm="12">
            <div class="font25">
              {{$t('completedPaymentInformation')}}
            </div>
          </v-col>
        </v-row>

        <v-row class="font12">
          <v-col cols="12" xs="12">
            <v-list id="transparent-background">
              <v-list-item>
                <v-row>
                  <v-col cols="6" xs="12" md="6">
                    {{$t('paidAmount')}}:
                  </v-col>
                  <v-col cols="6" xs="12" md="6">
                    <strong>{{paymentReceipt.amount}}</strong>
                  </v-col>
                </v-row>
              </v-list-item>
              <v-list-item>
                <v-row>
                  <v-col cols="6" xs="12" md="6">
                    {{$t('transactionId')}}:
                  </v-col>
                  <v-col cols="6" xs="12" md="6">
                    <strong>{{paymentReceipt.transaction_id}}</strong>
                  </v-col>
                </v-row>
              </v-list-item>
              <v-list-item>
                <v-row>
                  <v-col cols="6" xs="12" md="6">
                    {{$t('accountBalance')}}:
                  </v-col>
                  <v-col cols="6" xs="12" md="6">
                    <strong>${{paymentReceipt.balance}}</strong>
                  </v-col>
                </v-row>
              </v-list-item>
              <v-list-item>
                <v-row>
                  <v-col cols="6" xs="12" md="6">
                    {{$t('paymentMethod')}}:
                  </v-col>
                  <v-col cols="6" xs="12" md="6">
                    <strong>{{paymentInformation}}</strong>
                  </v-col>
                </v-row>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </div>
    </v-container>
</template>

<script>
export default {
  name: 'payment-completed-card',
  props: ['paymentReceipt', 'paymentInformation'],
};
</script>