<template>
  <div>
    <v-row>
      <v-col cols="12" sm="12">
        <div class="text-right">
          <v-btn 
          color="primary"
          @click="showCreateTicketDialog = true"
          >
            {{$t('createTicketBtn')}}
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="12">
        <tickets-datatable
        @error-message="showError"
        />
      </v-col>
    </v-row>

    <create-ticket-dialog 
    v-model="showCreateTicketDialog" 
    :showDialog="showCreateTicketDialog" 
    @closeDialog="closeDialog"
    @error-message="showError"
    />
  </div>
</template>

<script>
// importing child components
import TicketsDatatable from '@/components/Tickets/TicketsDatatable.vue';
import CreateTicketDialog from '@/components/Tickets/CreateTicketDialog.vue';

export default {
  name: 'trouble-tickets',
  // child components that are being displayed
  components: {
    CreateTicketDialog,
    TicketsDatatable,
  },
  // initializing the data
  data: () => ({
    showCreateTicketDialog: false,
  }),
  methods: {
    // closing create ticket dialog based on the value recieved (true or false)
    closeDialog(value) {
      this.showCreateTicketDialog = value;
    },
    // sets the error message component with the message passed 
    showError(msg) {
      this.$toast.error(msg, {
        timeout: 4000
      });
    }
  }
}
</script>
