<template>
    <v-card elevation="1" style="height: 100%;">
        <v-card-title>{{$t('yourBill')}}</v-card-title>

        <v-row>
            <v-col cols="12" lg="6">
                <v-card-text> 
                    <div class="my-5 font20">
                        {{$t('invoiceBalance')}}
                    </div>
                    <div v-if="lastInvoiceBalance!== null" class="my-5 font30">
                        {{formatToCurrency(lastInvoiceBalance)}}
                    </div>
                    <div v-else class="my-5 font30">
                        {{"$0.00"}}
                    </div>
                    <div v-if="lastInvoiceDueDate !== null">
                        {{$t('amountDueOn')}} {{$d(lastInvoiceDueDate, 'long')}}
                    </div>
                </v-card-text>
                <v-card-actions class="justify-center">
                    <v-btn
                    to="/billing?tab=makepayment"
                    color="blue-grey"
                    dark
                    >{{$t('makePayment')}}</v-btn>
                </v-card-actions>
            </v-col>

            <v-divider class="verDivider" vertical></v-divider>
            <div class="horDivider"></div>

            <v-col cols="12" lg="6">
                <v-card-text> 
                    <div class="mt-5 font20">
                        {{$t('billDetails')}}
                    </div>
                    <div class="my-3">
                        {{$t('seeMonthlyInvoice')}}
                    </div>
                    <div>
                        <router-link to="/billing?tab=invoice">{{$t('seeBill')}} ></router-link>
                    </div>
                </v-card-text>
            </v-col>

        </v-row>
        <br>
    </v-card>
</template>

<script>

export default {
    // receiving the last invoice data from parent component
    props: ['lastInvoiceBalance','lastInvoiceDueDate'],
    methods: {
        // formatting the currency input data
        formatToCurrency(variable) {
            return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD'}
            ).format(variable);
        },
    }
}
</script>
