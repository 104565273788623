<template>
  <div>
    <v-card elevation="1" class="my-5">
      <v-row>
        <v-col cols="12" lg="12">
          <v-card-text>
            <v-row class="font16">
              <v-col cols="5" md="4"> {{ $t("product") }}: </v-col>
              <v-col cols="5" md="4">
                <strong>{{ emailService.product_visible_name }}</strong>
              </v-col>
            </v-row>

            <v-row class="font16">
              <v-col cols="5" md="4"> {{ $t("email") }}: </v-col>
              <v-col cols="5" md="4">
                <strong>{{ emailService.id }}</strong>
              </v-col>
            </v-row>
          </v-card-text>
        </v-col>
      </v-row>
      <v-card-actions>
        <v-btn color="orange" text @click="showModalToResetPassword">
          {{ $t("resetPassword") }}
        </v-btn>

        <v-btn color="orange" text :href="this.getEmailCorrectUrl(emailService.id)" target="_blank">
          {{ $t("goToMail") }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog v-model="resetPasswordDialog" persistent max-width="530">
      <v-card>
        <v-card-title class="text-h5">
          {{ $t("serviceResetPasswordHeader") + " " + emailService.id }}
        </v-card-title>
        <v-form v-model="resetPasswordForm" ref="passwordForm">
          <v-card-text>
            <v-text-field label="New password" :rules="rules.reset" v-model="passwordInput" />
            <v-text-field label="Confirm password" :rules="rules.confirm" v-model="reEnterPasswordInput" />
            <ul style="display: block">
              <li>At least eight characters</li>
              <li>Must have uppercase characters</li>
              <li>Must have lowercase characters</li>
              <li>Must have numerical digits (0 through 9)</li>
              <li>Special characters (for example, ~, or !)</li>
            </ul>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn :disabled="!resetPasswordForm" @click="submitResetPassword">{{ $t("submitBtn") }}</v-btn>
            <v-btn @click="cancelResetPassword">{{ $t("cancelBtn") }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Api from "@/api/Api";

export default {
  // receiving customer email services from parent component
  props: ["emailService"],
  data: () => ({
    resetPasswordDialog: false,
    resetPasswordForm: false,
    passwordInput: "",
    reEnterPasswordInput: "",
    regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{}|[\]\\;:'",.<>/?]).{8,}$/,
    rules: {
      reset: [
        (v) => !!v || "Required"
      ],
      confirm: [
        (v) => !!v || "Required"
      ],
    }
  }),
  created() {
    this.rules.reset[1] = (v) => this.regex.test(v) || this.$i18n.t('resetPasswordValidation');
    this.rules.confirm[1] = (v) => this.regex.test(v) || this.$i18n.t('resetPasswordValidation');
    this.rules.confirm[2] = (v) => v === this.passwordInput || this.$i18n.t('resetPasswordMatching');
  },
  methods: {
    getEmailCorrectUrl(email) {
      const domain = email.split('@').pop()
      switch (domain) {
        case 'caribe.net':
          return 'http://webmail.caribe.net'
        case 'puertorico.org':
          return 'http://webmail.puertorico.org'

        default:
          break;
      }
      console.log('domain', domain);
      return "test"
    },
    showModalToResetPassword() {
      this.resetPasswordDialog = true;
    },
    cancelResetPassword() {
      this.$refs.passwordForm.reset();
      this.resetPasswordDialog = false;
    },
    async submitResetPassword() {
      try {
        this.$emit('loading', true);
        await Api.resetAwsPassword(this.$store.getters.token, {
          i_account: this.emailService.i_account,
          password: this.passwordInput,
        });

        this.resetPasswordDialog = false;
        this.$refs.passwordForm.reset();
        this.$emit('loading', false);
        this.$toast.success(this.$i18n.t('resetPasswordAceptance'), {
          timeout: 4000
        });
      } catch (error) {
        this.$emit('loading', false);
        console.log("error", error);
        this.$toast.error("Error occurred.Could not reset password.", {
          timeout: 4000
        });
      }
    },
  },
};
</script>
