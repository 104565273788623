<template>
  <v-container class="width50">
    <h3 class="display-5">{{ $t('paymentError') }}</h3>

    <span class="subheading">{{ $t('paymentErrorSubheading') }}</span>

    <v-divider class="my-3"></v-divider>
    <div class="mt-8">
      <v-row>
        <v-col cols="12" sm="12">
          <div class="font25">
            {{$t('paymentInformation')}}
          </div>
        </v-col>
      </v-row>

      <v-row class="font12">
        <v-col cols="12" xs="12">
          <v-list id="transparent-background">
            <v-list-item>
              <v-row>
                <v-col cols="6" xs="12" md="6">
                  {{$t('errorMsg')}}:
                </v-col>
                <v-col cols="6" xs="12" md="6">
                  <strong>{{errorMsg}}</strong>
                </v-col>
              </v-row>
            </v-list-item>
            <v-list-item>
              <v-row>
                <v-col cols="6" xs="12" md="6">
                  {{$t('accountBalance')}}:
                </v-col>
                <v-col cols="6" xs="12" md="6">
                  <strong>${{balance}}</strong>
                </v-col>
              </v-row>
            </v-list-item>
            <v-list-item>
              <v-row>
                <v-col cols="6" xs="12" md="6">
                  {{$t('paymentMethod')}}:
                </v-col>
                <v-col cols="6" xs="12" md="6">
                  <strong>{{paymentInformation}}</strong>
                </v-col>
              </v-row>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
export default {
  name: 'payment-completed-card',
  props: ['errorMsg', 'paymentInformation', 'balance'],
};
</script>