<template>
  <v-footer
  dark
  padless
  height="100"
  style="height: 0px"
  >
    <v-card
    class="black lighten-1 white--text text-center"
    width="100%"
    >
      <v-row class="mt-5">
        <v-col cols="12" sm="4" align="center">
          <v-card-text class="white--text pt-0" >
            <v-img
            height="60"
            width="250"
            :src="images.optico"
            ></v-img>
          </v-card-text>
        </v-col>

        <v-col cols="12" sm="4" align="center">
          <v-card-text class="white--text pt-0" >
            <div class="mb-5">
              <p class="mb-0">{{$t('sales')}}: <a href="mailto:optico@criticalhub.com" class="link">optico@criticalhub.com</a></p>
              <p class="mb-0">{{$t('billing')}}: <a href="mailto:billing@criticalhub.com" class="link">billing@criticalhub.com</a></p>
              <p class="mb-0">{{$t('techSupport')}}: <a href="mailto:support@criticalhub.com" class="link">support@criticalhub.com</a></p>
            </div>
          </v-card-text>
          <v-card-text class="white--text pt-0">
            <div class="mb-5">
              <p class="mb-0">{{$t('desk')}}: 787-957-6000</p>
              <p class="mb-0">{{$t('custService')}}: 787-728-9790</p>
            </div>
          </v-card-text>
        </v-col>

        <v-col cols="12" sm="4" align="center">
          <v-card-text class="white--text pt-0" >
            <div class="mb-5">
              <p class="mb-1"><a href="https://www.opticofiber.com/help/terms-of-service/" target="_blank" class="link">{{$t('termsOfService')}}</a></p>
              <p class="mb-1"><a href="https://www.opticofiber.com/help/policy/" target="_blank" class="link">{{$t('usePolicy')}}</a></p>
              <p class="mb-1"><a href="https://www.opticofiber.com/help/subscriber-agreement/" target="_blank" class="link">{{$t('suscriberAgreement')}}</a></p>
              <p class="mb-1"><a href="https://www.opticofiber.com/help/network-management/" target="_blank" class="link">{{$t('networkManagement')}}</a></p>
            </div>
          </v-card-text>
        </v-col>
      </v-row>

      <v-divider></v-divider>

      <v-card-text class="white--text">
        Copyright © {{ new Date().getFullYear() }} — <strong>Critical Hub Networks</strong>
        <v-row>
          <v-col cols="12">
            <v-card-text>
              <v-btn
              v-for="item in icons"
              :key="item.key"
              class="mx-2 white--text"
              icon
              :href="item.href"
              target="_blank"
              >
                <v-icon size="24px">
                  {{ item.icon }}
                </v-icon>
              </v-btn>
            </v-card-text>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
  export default {
    name: 'footer-',
    // initializing data
    data: () => ({
      // setting the icons that are displayed in footer
      icons: [
        {key: 1, icon: 'mdi-facebook', href: 'http://www.facebook.com/opticofiber'},
        {key: 2, icon: 'mdi-twitter', href: 'http://www.twitter.com/opticofiber' },
        {key: 3, icon: 'mdi-linkedin', href: 'http://www.linkedin.com/company/opticofiber/'},
        {key: 4, icon: 'mdi-instagram', href: 'http://www.instagram.com/opticofiber/'},
      ],
      // setting the image displayed in footer
      images: {
        optico: require('../assets/logos/critical_hub_logo.png')
      },
    }),
  }
</script>